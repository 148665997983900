import {
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  Typography,
  ListItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SxProps, Theme } from "@mui/material/styles";
import debounce from "lodash.debounce";
import {
  memo,
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { useSelectCountry } from "@/context/CountryContext";
import useEvent from "@/hooks/useEvent";
import { useRouter } from "next/navigation";
import { convertToUri } from "@/utils/stringMethods";
import {
  SearchParams,
  SearchParamsWithPreset,
} from "typesense/lib/Typesense/Documents";
import typesenseService from "@/services/TypesenseService";
import Image from "next/image";

// Define types for our component
interface SearchResult {
  id: number;
  title: string;
  description?: string;
  startedDate?: string;
  startedTime?: string;
  coverImage?: string | null | undefined;
}

interface AutocompleteSearchFieldProps {
  placeholder?: string;
  sx?: SxProps<Theme>;
}

const SEARCH_THRESHOLD = 2;
const DEBOUNCE_DELAY = 200;

const AutocompleteSearchField = memo(
  ({
    placeholder = "Rechercher des événements...",
    sx,
  }: AutocompleteSearchFieldProps) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [options, setOptions] = useState<SearchResult[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const router = useRouter();
    const { countryId } = useSelectCountry();
    const ref = useRef<HTMLDivElement>(null);
    const { eventDate } = useEvent();

    const getEvents = useCallback(
      (searchTerm: string) => {
        setLoading(true);
        const nowDate = new Date();
        const now = nowDate.getTime();

        const filter_array = [
          "isPublish:=true",
          "isValidate:=true",
          "isPrivate:=false",
          "delete:=false",
          `frequencyEndedDate:>=${now}`,
          `endedDate:>=${now}`,
        ];
        if (countryId) {
          filter_array.push(`countryId:=${countryId}`);
        }
        const filter_by = filter_array.join(" && ");
        const query: SearchParams | SearchParamsWithPreset = {
          filter_by,
          sort_by: "priority:desc,createdAt:desc",
          q: searchTerm || "*",
          query_by: "title,descriptions",
          limit: 20,
          include_fields: "title,id,startedDate,startedTime,coverUrl",
        };
        typesenseService
          .searchDocuments("events", { ...query })
          .then((searchResults) => {
            const apiResults = searchResults?.hits
              ?.map((hit: any) => {
                const document = hit.document;
                const startedDate = new Date(document.startedDate);
                const startedTime = document.startedTime
                  ? new Date(document.startedDate + document.startedTime)
                  : startedDate;
                return {
                  ...document,
                  startedDate: startedDate.toISOString(),
                  startedTime: startedTime.toISOString(),
                  coverImage: document.coverUrl,
                  description: undefined,
                };
              })
              .map((event) => ({
                ...event,
                description: event.startedDate
                  ? eventDate(event, false)
                  : undefined,
              }));

            if (apiResults?.length) {
              setOptions(apiResults);
            } else {
              setOptions([]);
            }
          })
          .catch((err) => {
            setOptions([]);
          })
          .finally(() => setLoading(false));
      },
      [countryId, setOptions]
    );

    // Create debounced search function
    const debouncedSearch = useRef(
      debounce((searchTerm: string) => {
        getEvents(searchTerm);
      }, DEBOUNCE_DELAY)
    ).current;

    // Clean up debounce on unmount
    useEffect(() => {
      debouncedSearch(inputValue);
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch, inputValue]);

    // Handle input changes
    const handleInputChange = useCallback(
      (_event: ChangeEvent<{}>, newInputValue: string) => {
        _event.stopPropagation();
        setInputValue(newInputValue);
      },
      []
    );

    // Handle selection
    const handleChange = useCallback(
      (_event: React.SyntheticEvent, newValue: SearchResult | null) => {
        _event.stopPropagation();
        if (newValue) {
          router.replace(
            `/evenement/${convertToUri(newValue.title)}-ID-${newValue.id}`
          );
        }
      },
      []
    );

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setOpen(false);
          ref.current?.blur();
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <Autocomplete
        sx={{
          width: "100%",
          ...sx,
          "& .MuiAutocomplete-listbox": {
            mt: 0, // Remove margin top from listbox
          },
          "& .MuiAutocomplete-paper": {
            mt: 0, // Remove margin top from paper
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={loading && inputValue.length >= SEARCH_THRESHOLD}
        filterOptions={(x) => x}
        noOptionsText={
          loading ? placeholder : `Aucun résultat pour "${inputValue}"`
        }
        clearOnBlur={false}
        clearOnEscape={false}
        handleHomeEndKeys={true}
        blurOnSelect={true}
        groupBy={() => "Résultats"}
        PaperComponent={({ children, ...other }) => (
          <Box
            {...other}
            sx={{
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              mt: -0.2,
              backgroundColor: "white",
              position: "fixed",
              marginX: inputValue ? 0.3 : 0.16,
              width: inputValue ? "99.6%" : "99.8%",
            }}
          >
            {children}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            ref={ref}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <SearchIcon
                  sx={{
                    color: open ? "black" : "rgba(255, 255, 255, 0.7)",
                    mr: 1,
                    transition: "color 0.3s ease",
                  }}
                />
              ),
              endAdornment: (
                <>
                  {loading && inputValue.length >= SEARCH_THRESHOLD ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "45px",
                borderRadius: 20,
                border: "1px solid #D3D3D3",
                ...(open && {
                  "&.Mui-focused": {
                    backgroundColor: "white",
                    borderRadius: 0,
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    border: "none",
                    borderBottom: "none",
                    "& input": {
                      color: "black",
                    },
                    boxShadow: "none",
                  },
                }),
              },
              "& input": {
                color: "white",
                padding: "20px 14px",
              },
              // Remove any field margin bottom that might cause spacing
              mb: 0,
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <ListItem
              {...props}
              sx={{
                py: 1,
                transition: "background-color 0.15s ease",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <SearchIcon
                    sx={{ color: "text.secondary", mr: 1.5, fontSize: 20 }}
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {option.coverImage && (
                      <Box sx={{ mx: 2 }}>
                        <Image
                          src={option.coverImage}
                          alt={option.title}
                          height={50}
                          width={50}
                          style={{ borderRadius: 4 }}
                        />
                      </Box>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body1">{option.title}</Typography>
                      {option.description && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontSize: "0.8rem", mt: 0.5 }}
                        >
                          {option.description}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        }}
        renderGroup={(params) => (
          <Box key={params.key}>
            <Box
              sx={{
                px: 2,
                py: 0.8,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                backgroundColor: "rgba(0, 0, 0, 0.02)",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontWeight: 500, fontSize: "0.75rem" }}
              >
                {params.group}
              </Typography>
            </Box>
            {params.children}
          </Box>
        )}
      />
    );
  }
);

export default AutocompleteSearchField;
