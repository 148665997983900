import { ApolloError } from "@apollo/client";

export const getValue = (value: any) => {
  return value && value != "null" && value != "0" ? value : "";
};

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const sliceText = (text: string, maxLength: number = 100): string => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

export const convertToUri = (title: string) => {
  if (title) {
    return encodeURIComponent(
      title
        .trimEnd()
        .trimStart()
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll("&", "-")
        .replaceAll(",", "-")
        .replaceAll(";", "-")
        .replaceAll("/", "-")
        .replaceAll("%", "-pourcent-")
        .replace(/-+/g, "-")
    )
      .replace(/%20/g, "-") // Remplacer les espaces codés par des tirets
      .replace(/%26/g, "-") // Remplacer les `&` codés par des tirets
      .replace(/%2C/g, "-") // Remplacer les `,` codés par des tirets
      .replace(/%3B/g, "-") // Remplacer les `;` codés par des tirets
      .replace(/%2F/g, "-") // Remplacer les `/` codés par des tirets
      .replace(/%25/g, "-pourcent-"); // Remplacer `%` codé par "-pourcent-"
  } else return null;
};

export function containsHtmlTags(text: string) {
  const htmlTagRegex = /<[^>]*>/g;
  return htmlTagRegex.test(text);
}

export function getErrorsAsString(
  error?: ApolloError | undefined
): string | string[] {
  if (error) {
    const message = error.message;
    if (message.includes("Unique constraint")) {
      return "Veuillez vérifier ces valeurs, car une ou plusieurs d'entre elles existent déjà.";
    } else if (message) {
      return message;
    }
  }
  return "Une erreur s'est produite. Veuillez réessayer, s'il vous plaît.";
}

export function isJSON(text: any) {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

export function isBlackFamily(color: string): boolean {
  // Convert HEX to RGB
  const hexToRgb = (hex: string) => {
    const normalizedHex = hex.replace(/^#/, "");
    const bigint = parseInt(normalizedHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  // Example tolerance for black shades
  const tolerance = 20;

  let rgb;
  if (color.startsWith("#")) {
    rgb = hexToRgb(color);
  } else if (color.startsWith("rgb")) {
    const rgbMatch = color.match(/\d+/g);
    if (rgbMatch) {
      rgb = {
        r: parseInt(rgbMatch[0], 10),
        g: parseInt(rgbMatch[1], 10),
        b: parseInt(rgbMatch[2], 10),
      };
    }
  }

  if (rgb) {
    const { r, g, b } = rgb;
    return r <= tolerance && g <= tolerance && b <= tolerance;
  }

  return false; // Default to false if color format isn't recognized
}

