import { parseISO } from "date-fns";
import { Event } from "@/gql/graphql";
import DOMPurify from "dompurify";
import truncate from "html-truncate";
import { getValue } from "@/utils/stringMethods";
import { toZonedTime, formatInTimeZone } from "date-fns-tz";
import { fr } from "date-fns/locale";

interface Cords {
  latitude?: number | null;
  longitude?: number | null;
}

const useEvent = () => {
  const getCords = ({
    city,
    country,
    place,
  }: {
    place?: Cords;
    city?: Cords;
    country?: Cords;
  }) => {
    if (place?.latitude && place?.longitude) {
      return {
        latitude: Number(place?.latitude),
        longitude: Number(place?.longitude),
      };
    } else if (city?.longitude && city.latitude) {
      return {
        latitude: Number(city.latitude),
        longitude: Number(city.longitude),
      };
    } else if (country?.latitude && country.longitude) {
      return {
        latitude: Number(country.latitude),
        longitude: Number(country.longitude),
      };
    }
    return undefined;
  };

  const getEventRegion = (event: Event) => {
    if (event?.longitude && event?.latitude) {
      return {
        latitude: Number(event?.latitude),
        longitude: Number(event?.longitude),
      };
    } else if (event?.place) {
      return {
        latitude: Number(event?.place.latitude),
        longitude: Number(event?.place.longitude),
      };
    } else {
      return {
        latitude: Number(event?.city?.latitude),
        longitude: Number(event?.city?.longitude),
      };
    }
  };

  function replaceTime(date1: string, date2: string) {
    let dateA = new Date(date1);
    let dateB = new Date(date2);
    dateA.setHours(dateB.getHours());
    dateA.setMinutes(dateB.getMinutes());
    dateA.setSeconds(dateB.getSeconds());

    return dateA;
  }

  const replaceTimeString = (
    date: string | undefined,
    time: string | undefined
  ): string | undefined => {
    if (!date || !time) return undefined;
    const [datePart] = date.split("T"); // Extrait la partie date si c'est au format ISO
    const [timePart] = time.split("T")[1]?.split("Z") || [time]; // Extrait la partie temps
    return `${datePart}T${timePart}Z`; // Combine les deux en format ISO
  };

  const eventTimeUct = (
    event: Event,
    isOnlyTime?: boolean,
    timeZone?: string
  ) => {
    if (!event?.startedDate || !event?.startedTime) return ""; // Gérer les cas manquants

    // Convertir les chaînes ISO en objets Date
    const startedDate = parseISO(event.startedDate);
    const startedTime = parseISO(event.startedTime);

    // Ajuster les dates avec le fuseau horaire (ou conserver UTC si timeZone non fourni)
    const zonedDate = timeZone
      ? toZonedTime(startedDate, timeZone)
      : startedDate;
    const zonedTime = timeZone
      ? toZonedTime(startedTime, timeZone)
      : startedTime;

    // Formatage de l'heure avec le fuseau horaire
    const formattedTime = formatInTimeZone(
      zonedTime,
      timeZone || "UTC",
      "HH:mm",
      { locale: fr }
    );

    // Format complet de la date et de l'heure
    const fullFormattedTime = `${
      (formatInTimeZone(zonedDate, timeZone || "UTC", "EEE d MMM"),
      { locale: fr })
    } à ${formattedTime}`;

    return isOnlyTime ? formattedTime : fullFormattedTime;
  };

  const eventTime = (event: Event, isEnd?: boolean, timeZone?: string) => {
    if (!event) return ""; // Gérer les cas où l'événement est null ou undefined

    // Choisir le bon champ en fonction de `isEnd`
    const time = isEnd ? event?.endedTime : event?.startedTime;
    if (!time) return ""; // Gérer les cas de temps invalide

    const zonedTime = timeZone
      ? toZonedTime(parseISO(time), timeZone)
      : parseISO(time);

    // Retourner l'heure formatée avec le fuseau horaire
    return formatInTimeZone(zonedTime, timeZone || "UTC", "HH:mm", {
      locale: fr,
    });
  };

  const eventDate = (
    event: Event,
    isEnd?: boolean,
    formatDate: string = "eee d MMM, HH:mm",
    timeZone?: string
  ): string => {
    const endedDate = replaceTimeString(event?.endedDate, event?.endedTime);
    const startedDate = replaceTimeString(
      event?.startedDate,
      event?.startedTime
    );

    const selectedDate = isEnd ? endedDate : startedDate;

    if (!selectedDate) return ""; // Gérer les dates invalides

    const zonedDate = timeZone
      ? toZonedTime(parseISO(selectedDate), timeZone)
      : parseISO(selectedDate);

    // Formate la date avec le fuseau horaire
    return formatInTimeZone(zonedDate, timeZone || "UTC", formatDate, {
      locale: fr,
    });
  };

  const getEventDescription = (event: Event, length?: number) => {
    if (event?.descriptions) {
      const sanitizedDescription = DOMPurify.sanitize(event?.descriptions);
      return length
        ? truncate(sanitizedDescription, length)
        : sanitizedDescription;
    }
    return ""; // Return an empty string for easier handling
  };

  const getEventLocation = (event: Event) => {
    if (event?.place) {
      return event?.place.name;
    } else if (event?.city) {
      const name = event?.city.name_fr;
      return name;
    }
    return (
      getValue(event?.creator.name) + " " + getValue(event?.creator.lastName)
    );
  };

  const openMobileApp = (eventId?: number | null) => {
    const appLink = `lehub://detail-event-user?eventId=${eventId}`;
    let hasAppOpened = false;

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = appLink;
    document.body.appendChild(iframe);

    const timeout = setTimeout(() => {
      if (typeof navigator !== "undefined") {
        if (!hasAppOpened) {
          const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
          const fallbackURL = isIOS
            ? `https://apps.apple.com/app/id6477760847?eventId=${eventId}`
            : `https://play.google.com/store/apps/details?id=com.lebarit.lehub&eventId=${eventId}`;

          window.location.href = fallbackURL;
        }
      }
    }, 2000);

    setTimeout(() => document.body.removeChild(iframe), 3000);

    const onFocus = () => {
      hasAppOpened = true;
      clearTimeout(timeout);
      window.removeEventListener("focus", onFocus);
    };

    window.addEventListener("focus", onFocus);
  };

  const eventTimeIntervalleUct = (event: Event) => {
    return `${eventDate(event, false)} 👉 ${eventDate(event, true)}`;
  };

  return {
    getEventRegion,
    eventTimeUct,
    eventTime,
    eventDate,
    getEventDescription,
    replaceTime,
    openMobileApp,
    getEventLocation,
    getCords,
    eventTimeIntervalleUct,
  };
};

export default useEvent;
