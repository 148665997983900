import config from "@/config/config";
import { Event } from "@/gql/graphql";
import { Client } from "typesense";
import { CollectionCreateSchema } from "typesense/lib/Typesense/Collections";
import {
  SearchParams,
  SearchParamsWithPreset,
  SearchResponse,
} from "typesense/lib/Typesense/Documents";

type CollectionName = "events";

class TypesenseService {
  private client: Client;

  constructor() {
    this.client = new Client({
      nodes: [
        {
          host: config.typesense.host, // Replace with your Typesense Cloud host
          port: 443,
          protocol: "https",
        },
      ],
      apiKey: config.typesense.adminKey, // Replace with your API Key
      connectionTimeoutSeconds: 500,
    });
  }

  /**
   * Create a new collection
   * @param collectionName Name of the collection
   * @param schema Collection schema
   */
  async createCollection(
    collectionName: CollectionName,
    schema: CollectionCreateSchema
  ) {
    try {
      schema.name = collectionName;
      const collection = await this.client.collections().create(schema);
      console.log(`Collection "${schema.name}" created successfully.`);
      return collection;
    } catch (error) {
      console.error("Error creating collection:", error);
    }
  }

  /**
   * Delete a collection
   * @param collectionName Name of the collection
   */
  async deleteCollection(collectionName: CollectionName) {
    try {
      const nameOfCollection = collectionName;
      await this.client.collections(nameOfCollection).delete();
      console.log(`Collection "${nameOfCollection}" deleted successfully.`);
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  }

  /**
   * Add or update a document
   * @param collectionName Name of the collection
   * @param document Document object
   */
  async upsertDocument(collectionName: CollectionName, document: any) {
    try {
      const nameOfCollection = collectionName;
      const response = await this.client
        .collections(nameOfCollection)
        .documents()
        .upsert(document);
      console.log(`Document in "${nameOfCollection}" upserted successfully.`);
      return response;
    } catch (error) {
      console.error("Error upserting document:", error);
    }
  }

  /**
   * Delete a document by ID
   * @param collectionName Name of the collection
   * @param documentId ID of the document
   */
  async deleteDocument(collectionName: CollectionName, documentId: string) {
    try {
      await this.client
        .collections(collectionName)
        .documents(documentId)
        .delete();
      console.log(`Document "${documentId}" deleted successfully.`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  }

  /**
   * Search for documents in a collection
   * @param collectionName Name of the collection
   * @param query Search query
   * @param queryBy Fields to search in
   */
  async searchDocuments(
    collectionName: CollectionName,
    search: SearchParams | SearchParamsWithPreset
  ): Promise<SearchResponse<object> | null> {
    try {
      const result: any = await this.client
        .collections(collectionName)
        .documents()
        .search(search);
      return result;
    } catch (error) {
      return null;
    }
  }

  async updateEventTypesense(event: Event) {
    await this.upsertDocument("events", {
      id: event.id.toString(),
      title: event.title,
      descriptions: event.descriptions,
      isPublish: event.isPublish,
      isValidate: event.isValidate,
      isPrivate: event.isPrivate,
      delete: event.delete,
      isValidateOnCompagny: event.isValidateOnCompagny,
      frequencyEndedDate: event.frequencyEndedDate?.getTime(),
      endedDate: event.endedDate?.getTime(),
      endedTime: event.endedTime?.getTime(),
      startedDate: event.startedDate.getTime(),
      createdAt: event.createdAt.getTime(),
      startedTime: event.startedTime?.getTime(),
      priority: event.priority,
      creatorId: event.creatorId,
      categoryIds: event?.categories?.map((cat) => cat.id),
      countryId: event.countryId,
      compagnyId: event.compagnyId,
      coverUrl: event.coverImage?.url ?? '',
      compagnyName: event.compagny?.name ?? '',
      pricings: event.ticketPricings?.map((el) => el.price),
      currency: event.country?.currency ?? 'XOF',
      creatorName: event.creator?.name ?? '',
      creatorLastName: event.creator?.lastName ?? '',
      countryName: event.country?.name_fr ?? '',
      categoryNames: event.categories?.map((el) => el.name),
    });
  }
}

const typesenseService = new TypesenseService();

export default typesenseService;
